.heading-portrait {
    bottom: 0;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.icon-large {
    height: 6rem;
    padding-top: 6rem;
    width: 6rem;
}
