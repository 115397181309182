$white: #FFFFFF;
$dark-white: #F5F5F5;
$gray: #858585;
$light-black: #1F1F1F;
$black: #000000;
$light-blue: #bde0fe;
$blue: #a2d2ff;
$light-purple: #cdb4db;
$purple: #B591CA;
$light-yellow: #F9D79F;
$yellow: #f6bd60;
$light-red: #F57A89;
$red: #F25467;
$light-green: #73D4AA;
$green: #57CC99;

:export {
    white: $white;
    dark-white: $dark-white;
    gray: $gray;
    light-black: $light-black;
    black: $black;
    light-blue: $light-blue;
    blue: $blue;
    light-purple: $light-purple;
    purple: $purple;
    light-yellow: $light-yellow;
    yellow: $yellow;
    light-red: $light-red;
    red: $red;
    light-green: $light-green;
    green: $green;
}