@use 'colors' as colors;

li {
    padding-bottom: 0.4rem;
}

.text-navigation {
    font-size: 1rem;
    font-weight: bold;
    padding: 0.6rem;
}

.text-large {
    font-size: 3rem;
    font-weight: bolder;
    padding: 1rem;
}

.text-medium {
    font-size: 2rem;
    font-weight: bold;
    padding: 0.8rem;
}

.text-small {
    font-size: 1rem;
    padding: 0.6rem;
}

.text-black {
    color: colors.$black;
}

.text-white {
    color: colors.$white;
}

.text-muted {
    color: colors.$gray;
}

.text-bold {
    font-weight: bold;
}

.text-indent {
    text-indent: 2rem;
}
