@use 'colors';

.background-parallax-blur {
    position: relative;
    overflow: auto;
}

.background-parallax-blur:before {
    content: "";
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    filter: blur(4px);
}

.background-white {
    background: colors.$white;
}

.background-dark-white {
    background: colors.$dark-white;
}

.background-black {
    background: colors.$black;
}

.background-light-black {
    background: colors.$light-black;
}

.background-red {
    background-image: linear-gradient(to left, colors.$light-red, colors.$red);
}

.background-yellow {
    background-image: linear-gradient(to left, colors.$light-yellow, colors.$yellow);
}

.background-green {
    background-image: linear-gradient(to left, colors.$light-green, colors.$green);
}

.background-blue {
    background-image: linear-gradient(to left, colors.$light-blue, colors.$blue);
}

.background-purple {
    background-image: linear-gradient(to left, colors.$light-purple, colors.$purple);
}

.background-colorful-light {
    background-image: linear-gradient(180deg, rgba(colors.$white, 0.5), rgba(colors.$white, 1) 100%),
        radial-gradient(ellipse at top left, rgba(colors.$yellow, 0.5), transparent 80%),
        radial-gradient(ellipse at top right, rgba(colors.$blue, 0.5), transparent 80%),
        radial-gradient(ellipse at bottom left, rgba(colors.$green, 0.5), transparent 80%),
        radial-gradient(ellipse at bottom right, rgba(colors.$purple, 0.5), transparent 80%);
}

.background-colorful-dark {
    background-image: linear-gradient(180deg, rgba(colors.$black, 0.5), rgba(colors.$black, 1) 100%),
        radial-gradient(ellipse at top left, rgba(colors.$purple, 0.5), transparent 80%),
        radial-gradient(ellipse at top right, rgba(colors.$blue, 0.5), transparent 80%),
        radial-gradient(ellipse at bottom left, rgba(colors.$red, 0.5), transparent 80%),
        radial-gradient(ellipse at bottom right, rgba(colors.$green, 0.5), transparent 80%);
}
