@use 'animations';
@use 'backgrounds';
@use 'images';
@use 'input';
@use 'layout';
@use 'navigation';
@use 'shapes';
@use 'text';
@use 'themes';

@use 'display';

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    line-height: 1.4;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

body::-webkit-scrollbar {
    display: none;
}

html, body {
    overflow-x: hidden;
}
