@use 'colors' as colors;

input {
    border: none;
    border-radius: 0.4rem;
    cursor: pointer;
    font-family: inherit;
    font-size: 1rem;
    margin: 0.4rem 0.2rem;
    padding: 0.4rem 0.8rem;
}

button {
    background-image: linear-gradient(to bottom, colors.$light-blue , colors.$blue);
    border: none;
    border-radius: 0.4rem;
    color: colors.$black;
    cursor: pointer;
    font-family: inherit;
    font-size: 1rem;
    font-weight: bold;
    margin: 0.4rem 0.2rem;
    padding: 0.4rem 0.8rem;
    transition: 0.4s;
}

button:hover {
    background-image: linear-gradient(to bottom, colors.$blue , colors.$blue);
}

button:active {
    background-image: linear-gradient(to bottom, colors.$light-blue , colors.$light-blue);
}

.button-white {
    background-image: linear-gradient(to bottom, colors.$white , colors.$dark-white);
}

.button-white:hover {
    background-image: linear-gradient(to bottom, colors.$dark-white , colors.$dark-white);
}

.button-white:active {
    background-image: linear-gradient(to bottom, colors.$white , colors.$white);
}

.button-black {
    background-image: linear-gradient(to bottom, colors.$black , colors.$light-black);
    color: colors.$white;
}

.button-black:hover {
    background-image: linear-gradient(to bottom, colors.$light-black , colors.$light-black);
}

.button-black:active {
    background-image: linear-gradient(to bottom, colors.$black , colors.$black);
}

.button-purple {
    background-image: linear-gradient(to bottom, colors.$light-purple , colors.$purple);
}

.button-purple:hover {
    background-image: linear-gradient(to bottom, colors.$purple , colors.$purple);
}

.button-purple:active {
    background-image: linear-gradient(to bottom, colors.$light-purple , colors.$light-purple);
}

.button-yellow {
    background-image: linear-gradient(to bottom, colors.$light-yellow , colors.$yellow);
}

.button-yellow:hover {
    background-image: linear-gradient(to bottom, colors.$yellow , colors.$yellow);
}

.button-yellow:active {
    background-image: linear-gradient(to bottom, colors.$light-yellow , colors.$light-yellow);
}

.button-red {
    background-image: linear-gradient(to bottom, colors.$light-red , colors.$red);
}

.button-red:hover {
    background-image: linear-gradient(to bottom, colors.$red , colors.$red);
}

.button-red:active {
    background-image: linear-gradient(to bottom, colors.$light-red , colors.$light-red);
}

.button-green {
    background-image: linear-gradient(to bottom, colors.$light-green , colors.$green);
}

.button-green:hover {
    background-image: linear-gradient(to bottom, colors.$green , colors.$green);
}

.button-green:active {
    background-image: linear-gradient(to bottom, colors.$light-green , colors.$light-green);
}