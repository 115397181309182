.position-relative {
    position: relative;
}

.row {
    display: grid;
}

.row-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.row-auto-min {
    display: grid;
    grid-template-columns: auto min-content;
}

.row-3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.column {
    display: inline-grid;
}

.column-2 {
    display: inline-grid;
    grid-column: span 2;
}

.container {
    position: relative;
}

.align-horizontal-left {
    text-align: left;
}

.align-horizontal-center {
    text-align: center;
}

.align-horizontal-right {
    text-align: right;
}

.align-vertical-center {
    position: absolute;
    top: 40%;
    -ms-transform: translateY(-40%);
    transform: translateY(-40%);
}

.margin-1 {
    margin: 1rem;
}

.margin-top-3 {
    margin-top: 3rem;
}

.margin-auto {
    margin: 0 auto;
}

.padding-1 {
    padding: 1rem;
}

.padding-3 {
    padding: 3rem;
}

.padding-top-3 {
    padding-top: 3rem;
}

.padding-top-5 {
    padding-top: 5rem;
}

.viewport-height-90 {
    height: 90vh;
}

.minimum-viewport-height-40 {
    min-height: 40vh;
}

.width-fit-content {
    display: inline-block;
    width: fit-content;
}

.width-100 {
    width: 100%;
}

.width-80 {
    width: 80%;
}

.max-width-40 {
    max-width: 40%;
}

.max-width-60 {
    max-width: 60%;
}

.rounded {
    border: none;
    border-radius: 0.4rem;
}
