.hide {
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: 0s;
}

.show {
    height: auto;
    opacity: 1;
}